<template>
  <div>
    <div class="modalPopup" id="company_search_modal">
      <div class="modal_header">
        <h3 class="title">수주 검색</h3>
        <button class="modal_close" @click="$emit('onclose')"></button>
      </div>
      <div class="modal_body">
        <div class="modal_search_opt"></div>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 4" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>매출처명</th>
                <th>제품명</th>
                <th>수주일</th>
                <th>납품요청일</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(sale, index) in filtered_sales"
                :key="sale.id"
                @click="selectedIndex = index"
                @dblclick="submit()"
                :class="selectedIndex == index ? 'active' : ''"
              >
                <td>
                  {{ findInfoFromId(companys, sale.company_id).name }}
                </td>
                <td>{{ getFirstSalesProduct(index) }}</td>
                <td>{{ sale.input_date | formatDateNoHours }}</td>
                <td>{{ sale.delivery_date | formatDateNoHours }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal_footer">
        <div class="warning" v-show="error_text != ''">
          {{ error_text }}
        </div>
        <button type="button" class="btn_sub2" @click="submit()">
          선택
        </button>
      </div>
    </div>
    <div class="modal_layer" @click="$emit('onclose')"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FetchMixin from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
export default {
  mixins: [SpinnerMixin, ModalMixin, FetchMixin, DRAG_MODAL_MIXIN],
  props: {
    search_type: {
      // 1 : 전체 수주
      // 2 : 미납품 수주
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error_text: '',
      selectedIndex: -1,
      search_value: '',
    };
  },
  computed: {
    ...mapGetters({
      sales: 'getSales',
      sales_type: 'getSalesType',
      plan_sales_type: 'getPlanSalesType',
      companys: 'getCompany',
      products: 'getProduct',
    }),
    filtered_sales() {
      if (this.plan_sales_type == undefined) return this.sales;
      if (this.search_type == 1) {
        return this.sales
          .filter(x => x.sales_type_id == this.plan_sales_type.id)
          .sort(
            (a, b) =>
              new Date(a.delivery_date) - new Date(b.delivery_date) ||
              a.id - b.id,
          );
      } else {
        console.log('this.sales', this.sales);
        console.log(this.plan_sales_type);
        return this.sales
          .filter(
            x =>
              x.sales_type_id == this.plan_sales_type.id &&
              x.completed == false,
          )
          .sort(
            (a, b) =>
              new Date(a.delivery_date) - new Date(b.delivery_date) ||
              a.id - b.id,
          );
      }
    },
  },
  methods: {
    getFirstSalesProduct(index) {
      const productCount = this.lodash
        .clonedeep(this.filtered_sales[index].product_list)
        .filter(x => x.parent_product_id == null);
      const firstProduct = this.findInfoFromId(
        this.products,
        productCount[0].product_id,
      ).name;
      return productCount.length > 1
        ? firstProduct + ' 외 ' + Number(productCount.length - 1) + '개 제품'
        : firstProduct;
    },
    async FETCH_SALES() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_SALES', {
          start_date: '1950-01-01',
          end_date: '2500-01-01',
          check_only_not_completed: false,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    submit() {
      if (this.selectedIndex == undefined || this.selectedIndex < 0) {
        this.error_text = `테이블의 행을 클릭하여 수주를 선택하여주십시오.`;
        return;
      }
      console.log(this.filtered_sales[this.selectedIndex]);
      this.$emit('onselect', this.filtered_sales[this.selectedIndex]);
    },
  },
  async created() {
    await this.FETCH_SALES();
    if (this.sales_type.length < 1) {
      await this.FETCH('FETCH_SALES_TYPE', '판매유형');
    }
  },
};
</script>

<style></style>
