<template>
  <div>
    <div class="modalPopup" id="product_search_modal">
      <div class="modal_header">
        <h3 class="title">
          {{ '투입 원자재 선택' }}
        </h3>
        <button class="modal_close" @click="isModalClose"></button>
      </div>
      <div class="modal_body">
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <thead>
              <tr>
                <th>원자재명</th>
                <th>규격</th>
                <th>현재고</th>
                <th>투입량</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in group_lists" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.standard }}</td>
                <td>
                  {{ $makeComma(item.stock)
                  }}{{ findInfoFromId(units, item.incoming_unit_id).name }}
                </td>
                <td>
                  <input
                    type="text"
                    inputmode="decimal"
                    :value="$makeComma(item.quantity)"
                    @input="
                      $inputNumber(
                        $event,
                        $data.group_lists[index],
                        'quantity',
                        item.stock,
                        0,
                      );
                      sumQuantity();
                    "
                  />
                </td>
              </tr>
              <tr class="total">
                <td class="text_right" colspan="3">합계</td>
                <td class="text_right">
                  {{ `${$makeComma(sumQuantityData)} / ${goal_value}` }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal_footer">
        <div class="warning" v-if="goal_value > sumQuantityData">
          {{
            `투입 필요 수량 : ${$makeComma(
              $decimalSub(goal_value, sumQuantityData),
            )}`
          }}
        </div>
        <button v-else type="button" class="btn_sub2" @click="submit()">
          선택
        </button>
      </div>
    </div>
    <div class="modal_layer" @click="isModalClose"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FetchMixin from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
export default {
  mixins: [SpinnerMixin, ModalMixin, FetchMixin, DRAG_MODAL_MIXIN],
  props: {
    group_resource: {
      type: Number,
      require: true,
    },
    goal_value: {
      type: Number,
      require: true,
    },
  },

  data() {
    return {
      error_text: '',
      selectedIndex: -1,
      search_value: '',
      search_type: null,
      showSelectric: false,
      group_lists: [],
      sumQuantityData: 0,
    };
  },
  computed: {
    ...mapGetters({
      materials: 'getMaterial',
      resource_types: 'getResourceTypeNotSort',
      material_groups: 'getMaterialGroup',
      material_stock_list: 'getMaterialTotalStock',
      material_occupied_stock: 'getMaterialOccupiedStock',
      units: 'getUnitCodes',
    }),

    filtered_resources() {
      const target_groups = this.lodash
        .clonedeep(
          this.material_groups.filter(
            x => x.group_material_id == this.group_resource,
          ),
        )
        .map(x => x.member_material_id);

      return this.materials.filter(x => target_groups.includes(x.id));
    },
  },
  methods: {
    sumQuantity() {
      const test = this.lodash.clonedeep(this.group_lists).map(x => x.quantity);
      this.sumQuantityData = test.reduce(
        (a, b) => this.$decimalAdd(this.$makeNumber(a), this.$makeNumber(b)),
        0,
      );
    },
    getMaterialStock(id) {
      const stock_quantity = this.findInfoFromId(this.material_stock_list, id)
        .stock_quantity;
      const occupied_stock = this.findInfoFromId(
        this.material_occupied_stock,
        id,
      ).quantity;

      const calStock = this.$decimalSub(
        stock_quantity != undefined ? stock_quantity : 0,
        occupied_stock != undefined ? occupied_stock : 0,
      );
      return calStock;
    },
    isModalClose() {
      this.$emit('onclose');
    },
    setGroupList() {
      const target_groups = this.lodash
        .clonedeep(
          this.material_groups.filter(
            x => x.group_material_id == this.group_resource,
          ),
        )
        .map(x => x.member_material_id);
      this.group_lists = this.lodash.clonedeep(
        this.materials.filter(x => target_groups.includes(x.id)),
      );
      this.group_lists.forEach(element => {
        element.stock = this.getMaterialStock(element.id);
        element.quantity = 0;
      });
      this.group_lists = this.group_lists.filter(x => x.stock > 0);
    },

    submit() {
      this.$emit(
        'onselect',
        this.group_lists.filter(x => this.$makeNumber(x.quantity) > 0),
      );
    },
  },
  async created() {
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.material_groups.length < 1) {
      await this.FETCH('FETCH_MATERIAL_GROUP', '자재 그룹');
    }
    this.setGroupList();
  },
};
</script>

<style></style>
