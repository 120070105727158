var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"modalPopup",attrs:{"id":"product_search_modal"}},[_c('div',{staticClass:"modal_header"},[_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s('투입 원자재 선택')+" ")]),_c('button',{staticClass:"modal_close",on:{"click":_vm.isModalClose}})]),_c('div',{staticClass:"modal_body"},[_c('div',{staticClass:"mes_tbl_wrap"},[_c('table',{staticClass:"mes_tbl"},[_vm._m(0),_c('tbody',[_vm._l((_vm.group_lists),function(item,index){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.standard))]),_c('td',[_vm._v(" "+_vm._s(_vm.$makeComma(item.stock))+_vm._s(_vm.findInfoFromId(_vm.units, item.incoming_unit_id).name)+" ")]),_c('td',[_c('input',{attrs:{"type":"text","inputmode":"decimal"},domProps:{"value":_vm.$makeComma(item.quantity)},on:{"input":function($event){_vm.$inputNumber(
                      $event,
                      _vm.$data.group_lists[index],
                      'quantity',
                      item.stock,
                      0
                    );
                    _vm.sumQuantity();}}})])])}),_c('tr',{staticClass:"total"},[_c('td',{staticClass:"text_right",attrs:{"colspan":"3"}},[_vm._v("합계")]),_c('td',{staticClass:"text_right"},[_vm._v(" "+_vm._s(((_vm.$makeComma(_vm.sumQuantityData)) + " / " + _vm.goal_value))+" ")])])],2)])])]),_c('div',{staticClass:"modal_footer"},[(_vm.goal_value > _vm.sumQuantityData)?_c('div',{staticClass:"warning"},[_vm._v(" "+_vm._s(("투입 필요 수량 : " + (_vm.$makeComma( _vm.$decimalSub(_vm.goal_value, _vm.sumQuantityData) ))))+" ")]):_c('button',{staticClass:"btn_sub2",attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" 선택 ")])])]),_c('div',{staticClass:"modal_layer",on:{"click":_vm.isModalClose}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("원자재명")]),_c('th',[_vm._v("규격")]),_c('th',[_vm._v("현재고")]),_c('th',[_vm._v("투입량")])])])}]

export { render, staticRenderFns }